import { _post } from "../utils/request.js";

const TMPURL =
  process.env.NODE_ENV === "development"
    ? "/uploadData"
    : process.env.VUE_APP_AWS;
const TMPUPLOAD =
  process.env.NODE_ENV === "development"
    ? "/uploadFile"
    : process.env.VUE_APP_AWS_UPLOAD;
//埋点上报
export function buryPointReport (params) {
  return _post(TMPURL, params);
}

//文件上传前置
export function fileUploadPre (params) {
  return _post(TMPURL + "/upload", params);
}

//文件上传
export function uploadFile (url, params) {
  return _post(url, params);
}
