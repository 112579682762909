import { createStore } from 'vuex'

export default createStore({
  state: {
    isCollapse: true
  },
  mutations: {
    CHANGE_IS_COLLAPSE: (state, isCollapse) => {
      state.isCollapse = isCollapse
    }
  },
  actions: {
    closeSide ({ commit }, data) {
      console.log('data', data)
      return new Promise(resolve => {
        const { isCollapse } = data
        commit('CHANGE_IS_COLLAPSE', isCollapse)
        resolve()
      })
    }
  },
  getters: {
    getIsCollapse: (state) => {
      return state.isCollapse
    },
  }
})

