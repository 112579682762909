<template>
  <div>
    <metainfo>
      <template v-slot:title="{ content }">{{ content ? `${content} | TOOLKIT` : `TOOLKIT` }}</template>
    </metainfo>
    <router-view></router-view>
  </div>
</template>

<script>
import Fingerprint2 from "fingerprintjs2"; // 引入fingerprintjs2
import { reportData } from './utils/buriedFunction/reportData.js'
import { useMeta } from 'vue-meta'
var md5 = require("md5");

export default {
  name: "App",
  components: {},
  async created () {
    // useMeta({
    //   title: 'Photo Effects & Filters',
    //   htmlAttrs: { lang: 'en', amp: true }
    // })
    let entryToolName
    console.log('window.location.pathname', window.location.pathname)
    switch (window.location.pathname) {
      case "/animation":
        entryToolName = "photo_animation"
        break;
      case "/filter":
        entryToolName = "photo_effects"
        break;
      case "/background-remover":
        entryToolName = "backGround_remover"
        break;
      case "/image-resizer":
        entryToolName = "image_resizer"
        break;
      case "/image-compressor":
        entryToolName = "image_compressor"
        break;
    }

    // 不应在页面加载时或加载后直接运行指纹。 而是使用setTimeout或requestIdleCallback将其延迟几毫秒，以确保指纹一致。
    if (window.requestIdleCallback) {
      requestIdleCallback(() => {
        this.createFingerprint();
      });
    } else {
      setTimeout(() => {
        this.createFingerprint();
      }, 500);
    }
    this.createSessionId();
    reportData("toolkits_page_view", { entry_tool: entryToolName })
  },
  methods: {

    // 创建浏览器指纹
    createFingerprint () {
      // 浏览器指纹
      const fingerprint = Fingerprint2.get((components) => {
        // 参数只有回调函数时，默认浏览器指纹依据所有配置信息进行生成
        const values = components.map((component) => component.value); // 配置的值的数组
        const murmur = Fingerprint2.x64hash128(values.join(""), 31); // 生成浏览器指纹
        localStorage.setItem("browserId", murmur); // 存储浏览器指纹，在项目中用于校验用户身份和埋点
      });
    },
    createSessionId () {
      const isHaveSessionId = sessionStorage.getItem("sessionId");
      //判断当前sessionStorage中是否有sessionId 没有则创建保存，有也跳过
      if (isHaveSessionId) {
        return;
      } else {
        const sessionId = md5(Date.now());
        sessionStorage.setItem("sessionId", sessionId);
        return;
      }
    },
  },
};
</script>

<style lang="less">
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  &>div {
    width: 100%;
    height: 100%;
  }
}
</style>
