import * as mathUtils from "./math";
import * as runModelUtils from "./runModel";

const getSessionsSource = function () {
  return (
    window.location.search.split("?")[1]?.replace("utm_source=", "") ||
    "organic"
  );
};

const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

export { mathUtils, runModelUtils, getSessionsSource, getDeviceType };
