"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var App_vue_1 = require("./App.vue");
var vue_meta_1 = require("vue-meta");
var ant_design_vue_1 = require("ant-design-vue");
require("ant-design-vue/dist/antd.less");
var store_1 = require("./store");
var router_1 = require("./router");
var vue_drag_drop_1 = require("vue-drag-drop");
vue_1.createApp(App_vue_1.default)
    .use(ant_design_vue_1.default)
    .use(store_1.default)
    .use(router_1.default)
    .use(vue_drag_drop_1.default)
    .use(vue_meta_1.createMetaManager())
    .mount('#app');
