import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";

const constantRouterMap = [
  {
    path: "/",
    name: "home",
    component: () => import("@/layouts/index.vue"),
    redirect: "/filter",
    children: [
      {
        path: "/background-remover",
        name: "backGroundRemover",
        component: () => import("@/views/models/bgRemover.vue"),
      },
      {
        path: "/removeClothBackground",
        name: "removeClothBackground",
        component: () => import("@/views/models/removeClothBackground.vue"),
      },
      {
        path: "/filter",
        name: "filter",
        component: () => import("@/views/models/filter.vue"),
      },
      {
        path: "/animation",
        name: "dynamicEffects",
        component: () => import("@/views/models/imageDynamicEffects.vue"),
      },
      {
        path: "/image-resizer",
        name: "imageResizer",
        component: () => import("@/views/models/imageResizer.vue"),
      },
      {
        path: "/image-compressor",
        name: "imageCompressor",
        component: () => import("@/views/models/imageCompressor.vue"),
      },
      {
        path: "/terms",
        name: "termsOfService",
        component: () => import("@/views/policy/termsOfService.vue"),
      },
      {
        path: "/privacy",
        name: "privacyPolicy",
        component: () => import("@/views/policy/privacyPolicy.vue"),
      },
      {
        path: "/cookies",
        name: "cookiesPolicy",
        component: () => import("@/views/policy/cookiesPolicy.vue"),
      },
      {
        path: "/:pathMatch(.*)*",
        redirect: "/",
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: constantRouterMap,
});

router.beforeEach((to, from, next) => {
  if (to.hash && to.hash.includes("#")) {
    const path = to.hash.split("#");
    next({ path: path[1] });
  } else {
    if (JSON.stringify(to.query) !== "") {
      next();
      return;
    }
    if (JSON.stringify(from.query) !== "") {
      let toQuery = JSON.parse(JSON.stringify(to.query));
      toQuery = from.query;
      next({
        path: to.path,
        query: toQuery,
      });
    } else {
      next();
    }
  }
});

export default router;
