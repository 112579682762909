import axios from "axios";

// 创建axios实例，设置配置得默认值
const instance = axios.create({
  baseURL: window.location.protocol + "//" + window.location.host, // 默认的baseURL
  timeout: 10000, // 设置请求超时的默认值
});

// 设置请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 判断当前是否有token，有则在请求头上加上token
    if (localStorage.getItem("token")) {
      config.headers.Authorization = localStorage.getItem("token");
    }
    return config;
  },
  (error) => {
    // 请求错误进行拦截并返回错误信息
    return Promise.reject(error);
  }
);

// 设置响应拦截
instance.interceptors.response.use(
  (data) => {
    let result = {
      success: true,
      ...data,
    };
    return Promise.resolve(result);
  },
  (error) => {
    return Promise.reject(error);
  }
);

/*
 *  post请求
 *  url:请求地址
 *  params:参数
 * */
export function _post(url, params) {
  return new Promise((resolve, reject) => {
    instance({
      url: url,
      method: "post",
      data: params,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
